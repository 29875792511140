import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import { AutoComplete, Button, DatePicker, Select, Table, Tag } from "antd";
import TextArea from 'antd/lib/input/TextArea';
import { cloneDeep } from 'lodash';

import BaseCheckboxList from '../../../../../../../../../../components/BaseCheckboxList/BaseCheckboxList';
import BaseCard from "../../../../../../../../../../components/BaseCard/BaseCard";
import ImageComponent from "../../../../../../../../../../components/ImageComponent/ImageComponent";

import { getOnPropertiesData, renderListClickableItems, sortByName } from "../../../../../../../../../../services/util-service/util.service";
import { getBannerSetDetailsRoute } from "../../../../../../../../../../services/navigation/banners-navigation/banners-navigation-routes.service";
import {
  renderMomentWithHours,
  convertMomentToDate,
  renderMoment,
  momentFormats
} from "../../../../../../../../../../services/date-service/date.service";

import { searchCustomPreview } from "../../../../../../../../../../services/video-service/video.service";

import {
  displaySuccessNotification,
  displayErrorNotification
} from "../../../../../../../../../../services/notification-service/notification.service";

import {
  editBanner,
  disableBanner,
  enableBanner
} from '../../../../../../../../../../services/banners-service/banners.service';
import { showPropertyDetails } from "../../../../../../../../../../services/navigation/properties-navigation/properties-navigation.service";
import { getPropertyDetailsRoute } from "../../../../../../../../../../services/navigation/properties-navigation/properties-navigation-routes.service";
import moment from 'moment';
import { findLargesBannerImage } from '../../../../../../../../../../services/images-service/images.service';
import './BannerDetailsInfosTab.scss';

class BannerDetailsInfosTab extends Component {
  filterDelay = 300;

  startingData = {};

  editableProperties = [
    'title',
    'caption',
    'status',
    'url',
    'videoClipUrl',
    'publishedDate',
    'publishedEnd',
    'sets',
    'movies_id',
    'scenes_id',
    'exclusive',
    'excluded',
    'movie_playlists_id',
    'scene_playlists_id',
    'tags_id',
    'studios_id',
    'overlay_text',
    'overlay_title',
    'overlay_subtitle'
  ];

  state = {
    bannersSets: [],
    data: {},
    editMode: false
  }

  constructor(props) {
    super(props);
    const {
      banners_sets = [],
      movies_id,
      scenes_id,
      title,
      url,
      caption,
      videoClipUrl,
      movie_playlists_id,
      scene_playlists_id,
      publishedDate,
      publishedEnd,
      status,
      tags_id,
      studios_id,
      overlay_title,
      overlay_subtitle,
      overlay_text,
      banners_on_properties_data
    } = props.data;
    const { properties } = this.props;
    const { exclusive = [], excluded = [] } = getOnPropertiesData(banners_on_properties_data, properties);

    const state = {
      bannersSets: props.bannersSets.sort(sortByName),
      data: {
        exclusive: exclusive.map(this.getItemIds),
        excluded: excluded.map(this.getItemIds),
        selectedBannersSets: banners_sets.map(this.getItemIds),
        caption,
        enabled: status,
        movies_id,
        scenes_id,
        movie_playlists_id,
        scene_playlists_id,
        publishedDate: publishedDate,
        publishedEnd: publishedEnd,
        title,
        url,
        videoClipUrl,
        tags_id,
        studios_id,
        overlay_title,
        overlay_subtitle,
        overlay_text
      },
      properties: props.properties.sort(sortByName)
    };

    this.startingData = cloneDeep(state.data);
    Object.assign(this.state, state);
  }

  getItemIds = item => {
    return item.id;
  };

  getBannerSetsList = () => {
    const { bannersSets, data } = this.state;
    return (
      <div>
        {bannersSets.length && <BaseCheckboxList items={bannersSets}
          checked={data.selectedBannersSets}
          onChange={this.onChange.bind(this, 'selectedBannersSets')}
          showSelectAll />}
      </div>
    );
  };

  getPublishDate = (key) => {
    const data = {};
    const value = this.state.data[key];
    if (value) {
      data[key] = convertMomentToDate(value, true, true);
    } else {
      const prevValue = this.startingData[key];
      if (prevValue) {
        data[key] = null;
      }
    }

    return data;
  };

  isEditable = (key) => {
    return this.editableProperties.indexOf(key) !== -1;
  };

  onChange = (key, event) => {
    let value = event;
    if (event) {
      value = event.target ? event.target.value : event;
    }
    this.setState({
      data: {
        ...this.state.data,
        [key]: value
      }
    });
  };

  onVideoClipUrlSearch = (searchText) => {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this.timeoutId = setTimeout(this.searchItems.bind(this, searchText), this.filterDelay);
  };

  renderDatePicker = (value, onChange) => {
    return (
      <DatePicker
        showToday
        showTime
        value={moment(value).isValid() ? moment(value) : null}
        format={momentFormats.dateHours}
        onChange={onChange} />
    );
  };

  renderEnabledDisabled = () => {
    const { t } = this.props;
    const { enabled } = this.state.data;
    return (
      <Select onChange={this.onChange.bind(this, 'enabled')}
        value={enabled}>
        <Select.Option value={1} key="enabled">
          {t('BannerDetailsTable.enabled')}
        </Select.Option>
        <Select.Option value={0} key="disabled">
          {t('BannerDetailsTable.disabled')}
        </Select.Option>
      </Select>
    );
  };

  renderExclusiveExcludedList = key => {
    const { properties, data } = this.state;
    return (
      <div>
        {properties.length && <BaseCheckboxList items={properties}
          checked={data[key]}
          onChange={this.onChange.bind(this, key)}
          showSelectAll />}
      </div>
    );
  };

  renderGeneralInfoValue = (value, { key }) => {
    const { editMode } = this.state;
    let render = value;
    if (editMode && this.isEditable(key)) {
      switch (key) {
        case 'publishedDate':
        case 'publishedEnd':
          render = this.renderDatePicker(this.state.data[key], this.onChange.bind(this, key));
          break;
        case 'status':
          render = this.renderEnabledDisabled();
          break;
        case 'sets':
          render = this.getBannerSetsList();
          break;
        case 'exclusive':
        case 'excluded':
          render = this.renderExclusiveExcludedList(key);
          break;
        case 'videoClipUrl':
          render = this.renderVideoSearchField();
          break;
        default:
          render = (
            <TextArea
              autoFocus={key === 'movies_id'}
              onChange={this.onChange.bind(this, key)}
              value={this.state.data[key]}
            />
          );
      }
    }

    return render;
  };

  renderVideoClipUrl = (item) => {
    let url = '';
    if (item) {
      url = (
        <span className="Url-Container">
          <a href={item} target="_blank" rel="noopener noreferrer">{item}</a>
        </span>
      );
    }
    return url;
  };

  renderVideoSearchField = () => {
    const { options, data } = this.state;
    const { videoClipUrl } = data;

    return (
      <AutoComplete autosize
        onSearch={this.onVideoClipUrlSearch}
        dataSource={options}
        onChange={this.onChange.bind(this, 'videoClipUrl')}
        value={videoClipUrl} />
    );
  };

  infoColumns = [{
    dataIndex: 'name',
    key: 'name',
    width: 300
  }, {
    dataIndex: 'value',
    render: this.renderGeneralInfoValue.bind(this),
    width: '100%'
  }];

  getBannerStatusUpdatePromise = () => {
    let promise;
    const { enabled: initialStatus } = this.startingData;
    const { enabled } = this.state.data;

    if (initialStatus !== enabled) {
      const { id: bannerId } = this.props.data;
      promise = enabled ? enableBanner(bannerId) : disableBanner(bannerId);
    } else {
      promise = Promise.resolve();
    }

    return promise;
  };

  /**
   * Returns details table data array
   *
   * @returns {Object[]}
   */
  getDetailsTableData = () => {
    const { t, data, properties } = this.props;
    const { exclusive, excluded } = getOnPropertiesData(data.banners_on_properties_data, properties);

    return [{
      name: t('BannerDetailsTable.movieId'),
      value: data.movies_id,
      key: 'movies_id'
    }, {
      name: 'Scene ID',
      value: data.scenes_id,
      key: 'scenes_id'
    }, {
      name: t('BannerDetailsTable.title'),
      value: data.title,
      key: 'title'
    }, {
      name: t('BannerDetailsTable.caption'),
      value: data.caption,
      key: 'caption'
    }, {
      name: t('BannerDetailsTable.status'),
      value: this.renderStatus(data.status),
      key: 'status'
    }, {
      name: t('BannerDetailsTable.url'),
      value: data.url,
      key: 'url'
    }, {
      name: t('BannerDetailsTable.videoClipUrl'),
      value: this.renderVideoClipUrl(data.videoClipUrl),
      key: 'videoClipUrl'
    }, {
      name: t('BannerDetailsTable.tagId'),
      value: data.tags_id,
      key: 'tags_id'
    }, {
      name: t('BannerDetailsTable.studioId'),
      value: data.studios_id,
      key: 'studios_id'
    }, {
      name: t('BannerDetailsTable.moviePlaylistId'),
      value: data.movie_playlists_id,
      key: 'movie_playlists_id'
    }, {
      name: t('BannerDetailsTable.scenePlaylistId'),
      value: data.scene_playlists_id,
      key: 'scene_playlists_id'
    }, {
      name: t('BannerDetailsTable.overlayTop'),
      value: data.overlay_title,
      key: 'overlay_title'
    }, {
      name: t('BannerDetailsTable.overlayMiddle'),
      value: data.overlay_subtitle,
      key: 'overlay_subtitle'
    }, {
      name: t('BannerDetailsTable.overlayBottom'),
      value: data.overlay_text,
      key: 'overlay_text'
    }, {
      name: t('BannerDetailsTable.publishedDate'),
      value: renderMoment(data.publishedDate, momentFormats.dateHours),
      key: 'publishedDate'
    }, {
      name: t('BannerDetailsTable.publishedEnd'),
      value: renderMoment(data.publishedEnd, momentFormats.dateHours),
      key: 'publishedEnd'
    }, {
      name: t('BannerDetailsTable.sets'),
      value: renderListClickableItems(data.banners_sets, undefined, 'bannerSetsList', getBannerSetDetailsRoute),
      key: 'sets'
    }, {
      name: t('BannerDetailsTable.created'),
      value: renderMomentWithHours(data.created_at),
      key: 'created'
    }, {
      name: t('BannerDetailsTable.exclusive'),
      value: renderListClickableItems(exclusive, showPropertyDetails, 'exclusiveProperties', getPropertyDetailsRoute),
      key: 'exclusive'
    }, {
      name: t('BannerDetailsTable.excluded'),
      value: renderListClickableItems(excluded, showPropertyDetails, 'exclusiveProperties', getPropertyDetailsRoute),
      key: 'excluded'
    }];
  };

  hasDataChanged = () => {
    return JSON.stringify(this.state.data) !== JSON.stringify(this.startingData);
  };

  onBannerEdit = () => {
    const message = this.props.t('BannerDetailsTable.savingMsg');
    this.props.setLoading(message);

    const { id: bannerId } = this.props.data;
    const {
      movies_id,
      scenes_id,
      title,
      url,
      caption,
      videoClipUrl,
      selectedBannersSets,
      tags_id,
      studios_id,
      movie_playlists_id,
      scene_playlists_id,
      overlay_text,
      overlay_title,
      overlay_subtitle,
      exclusive,
      excluded
    } = this.state.data;

    const { properties = [] } = this.props;
    const bannersOnPropertiesDataPost = properties.map(({ id }) => {
      const data = {
        properties_id: id,
        exclusive_excluded: null
      };

      if (exclusive.includes(id)) {
        data.exclusive_excluded = 'exclusive';
      } else if (excluded.includes(id)) {
        data.exclusive_excluded = 'excluded';
      }

      return data;
    });

    const postData = {
      bannerSets: selectedBannersSets,
      caption,
      title,
      url,
      videoClipUrl,
      overlay_text,
      overlay_title,
      overlay_subtitle,
      bannersOnPropertiesData: bannersOnPropertiesDataPost
    };

    Object.assign(postData, {
      movie_playlists_id,
      scene_playlists_id,
      studios_id,
      tags_id,
      movies_id,
      scenes_id
    });

    Object.assign(postData, this.getPublishDate('publishedDate'));
    Object.assign(postData, this.getPublishDate('publishedEnd'));

    const promises = [
      editBanner(bannerId, postData),
      this.getBannerStatusUpdatePromise()
    ];

    Promise.all(promises)
      .then(this.onBannerEditSuccess)
      .catch(this.onBannerEditFailed);
  };

  onBannerEditFailed = () => {
    this.props.setLoading();
    displayErrorNotification({
      duration: 3,
      message: this.props.t('BannerDetailsTable.editBannerFailure')
    });
  }

  onBannerEditSuccess = () => {
    displaySuccessNotification({
      duration: 3,
      message: this.props.t('BannerDetailsTable.editBannerSuccess')
    });
    this.startingData.enabled = this.state.data.enabled;
    this.toggleEditMode();
    this.props.onBannerEdit()
      .then(() => {
        const { data = {} } = this.props;
        this.setState((prevState = {}) => {
          return {
            ...prevState,
            data: {
              ...prevState.data,
              title: data.title || '',
              caption: data.caption || '',
              url: data.url || '',
            }
          }
        })
      });
  };

  renderBannerImage = () => {
    const image = this.props.data ? this.props.data.image : [];
    let img = null;
    const desktopImg = findLargesBannerImage(image);

    if (desktopImg) {
      img = (
        <ImageComponent url={desktopImg} />
      );
    }
    return img;
  };

  renderStatus = (status) => {
    const { t } = this.props;
    let text;
    let className;
    if (status) {
      text = t('BannerDetailsTable.enabled');
      className = 'Enabled';
    } else {
      text = t('BannerDetailsTable.disabled');
      className = 'Disabled';
    }
    return (
      <Tag className={className}>
        {text}
      </Tag>
    );
  };

  /**
   * Returns table element for render
   */
  renderTable = (columns, data, className, footer, rowKey) => {
    if (!rowKey) {
      rowKey = 'key';
    }
    return (
      <Table
        size='small'
        className={className}
        dataSource={data}
        columns={columns}
        showHeader={false}
        pagination={false}
        rowKey={rowKey}
        bordered
        footer={footer} />
    );
  };

  renderInfoTableFooter = () => {
    const { t } = this.props;
    const { editMode } = this.state;
    const key = editMode ? 'cancel' : 'editBtn';
    return (
      <div className="InfoTableFooter">
        {editMode ?
          (<Button onClick={this.onBannerEdit}
            disabled={!this.hasDataChanged()}>
            {t('BannerDetailsBody.saveChanges')}
          </Button>) : null}
        <Button onClick={this.toggleEditMode}>
          {t(`BannerDetailsBody.${key}`)}
        </Button>
      </div>
    );
  };

  toggleEditMode = () => {
    this.setState(prevState => {
      return {
        editMode: !prevState.editMode
      };
    });
  };

  searchItems = (searchText) => {
    searchCustomPreview(searchText)
      .then(this.setCustomPreviewOptions);
  };

  setCustomPreviewOptions = (response) => {
    const { data } = response.data;
    this.setState({
      options: data
    });
  };

  render() {
    const { t } = this.props;
    return (
      <div className="BannerDetailsInfosTab">
        {this.renderBannerImage()}
        <BaseCard title={t('BannerDetailsBody.info')}>
          {this.renderTable(this.infoColumns, this.getDetailsTableData(), 'BannerDetailsTable', this.renderInfoTableFooter)}
        </BaseCard>
      </div>
    );
  }
}

BannerDetailsInfosTab.propTypes = {
  data: PropTypes.object.isRequired
};

export default withTranslation()(BannerDetailsInfosTab);
