import axios from 'axios';

export const urls = {
  banners: '/banners',
  bannerSets: '/banners_set'
};

export const addBanner = data => {
  return axios.post(urls.banners, data);
};

export const addBannerToSet = (bannerSetId, data) => {
  return axios.patch(`${urls.bannerSets}/${bannerSetId}/attach_banners`, null, {
    params: {
      banner_id: data
    }
  });
};

export const deleteBanner = (bannerId) => {
  return axios.delete(`${urls.banners}/${bannerId}`);
};

export const disableBanner = (bannerId) => {
  return axios.get(`${urls.banners}/switcher/${bannerId}/disable`, {
    params: {
      id: bannerId
    }
  });
};

export const editBanner = (id, data = {}) => {
  const { bannerSets, bannersOnPropertiesData, ...rest } = data || {};
  const params = {
    banners_set: bannerSets,
    ...rest
  };

  return axios.patch(`${urls.banners}/${id}`,
    { banners_on_properties_data: bannersOnPropertiesData },
    { params }
  );
};

export const editBannerExclusiveExcluded = (id, data) => {
  const { excluded, bannerSetIds } = data || {};

  return axios.patch(`${urls.banners}/${id}`,
    {
      banners_set: bannerSetIds,
      banners_on_properties_data: excluded
    }
  );
};

export const editBannerSet = (bannerSetId, properties) => {
  return axios.patch(`${urls.bannerSets}/${bannerSetId}`, undefined, {
    params: {
      ...properties
    }
  });
};

export const createNewBannerSet = (properties) => {
  return axios.post(`${urls.bannerSets}`, {
    ...properties
  })
}

export const removeBannerSet = (id) => {
  return axios.delete(`${urls.bannerSets}/${id}`);
}

export const enableBanner = (bannerId) => {
  return axios.get(`${urls.banners}/switcher/${bannerId}/enable`, {
    params: {
      id: bannerId
    }
  });
};

export const getBanners = (page, filters) => {
  return filters.length
    ? getFilteredBanners(page, filters)
    : getBannersByPage(page);
};

export const getBannersByProperty = (bannerSetId, propertyId) => {
  return axios.get(`/banners/banners_set/${bannerSetId}/propertieswithbanner?properties_id=${propertyId}`);
};

export const getBannerSets = (limit, sort_by, sort_order, published_only) => {
  if (!limit) {
    limit = 0;
  }
  const params = { limit, published_only };
  if (sort_by) {
    Object.assign(params, {
      sort_by, sort_order
    })
  }
  return axios.get(`${urls.bannerSets}`, { params });
};

export const getBannerSetById = (bannerSetId, page, sort_by, sort_order, limit, properties_id) => {
  const params = { page };
  if (sort_by) {
    Object.assign(params, {
      sort_by, sort_order
    })
  }
  if (limit !== undefined) {
    params.limit = limit;
  }
  if (properties_id !== undefined) {
    params.properties_id = properties_id;
  }
  return axios.get(`${urls.bannerSets}/${bannerSetId}`, { params });
};

export const getFilteredBanners = (page, filters) => {
  return axios.get(`${urls.banners}?${filters.join('&')}&page=${page}`);
};

export const getBannerById = bannerId => {
  return axios.get(`${urls.banners}/${bannerId}`);
};

export const getBannersByPage = page => {
  return axios.get(`${urls.banners}?page=${page}`);
};

export const getBannerSetBanners = (setId, page) => {
  return axios.get(`banners/banners_set/${setId}?page=${page}`);
};

export const getBannerSetProperties = (setId, page, sort_by, sort_order, limit) => {
  const params = {};
  if (page) {
    params.page = page;
  }
  if (sort_by) {
    Object.assign(params, {
      sort_by, sort_order
    });
  }
  if (limit !== undefined) {
    params.limit = limit;
  }
  return axios.get(`/banners_set/${setId}/properties`, { params });
};

export const getBannerSetBannersList = (setId, sort_by, sort_order, properties_id) => {
  const params = {};
  if (sort_by) {
    Object.assign(params, {
      sort_by, sort_order
    });
  }
  if (properties_id !== undefined) {
    params.properties_id = properties_id;
  }
  return axios.get(`/banners_set/${setId}/list`, { params });
};

export const getDefaultBannerSetSaveKey = (bannerSetId) => `DefaultBannerSet=${bannerSetId}`;

export const getBannerSetPropertySaveKey = (bannerSetId, propertyId) => `BannerSet=${bannerSetId}&&Property=${propertyId}`;

export const removeBannerFromSet = (bannerSetId, data) => {
  return axios.patch(`${urls.bannerSets}/${bannerSetId}/detach_banners`, null, {
    params: {
      banner_id: data
    }
  });
};

export const publishUnpublishedBanners = () => {
  return axios.patch(`${urls.banners}/banners_set/publish_unpublished`);
}

export const setBannerSetBannersOrder = (params) => {
  return axios.patch(`${urls.banners}/banners_set/reorder`, params);
};

