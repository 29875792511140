import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { compose } from "recompose";
import { withDocumentTitleUpdate } from "../../../../../../../../hoc/withDocumentTitleUpdate/withDocumentTitleUpdate";

import AddBannerModal from "./AddBannerModal/AddBannerModal";
import BannersMainView from "./BannersMainView/BannersMainView";
import UploadBannersModal from "./UploadBannersModal/UploadBannersModal";
import ConfirmationModal from "../../../../../../../../components/modal/ConfirmationModal/ConfirmationModal";
import NewBannerSetModal from "./NewBannerSetModal/NewBannerSetModal.jsx";

import {
  addBanner,
  deleteBanner,
  getBanners,
  publishUnpublishedBanners,
} from "../../../../../../../../services/banners-service/banners.service";
import { uploadBannerImage } from "../../../../../../../../services/images-service/images.service";
import { ModalController } from "../../../../../../../../controllers/modal-controller/modal.controller";
import { BannerController } from "../../../../../../../../controllers/banner-controller/banner.controller";

import jsonData from "./data";

import "./BannersViewSection.scss";
import { displayErrorNotification, displaySuccessNotification } from "../../../../../../../../services/notification-service/notification.service";

class BannersViewSection extends Component {
  state = {
    bannersToDelete: [],
    bannersLoaded: false,
    bannersData: [],
    bannersTotal: 0,
    currentPage: 0,
    errorMsg: "",
    loadingMessage: "",
    pagesTotal: 0,
    pageSize: 0,
    recordCount: 0,
    showSets: false
  };

  bannerFilters = {
    all: "all",
    disable: "enable=0",
    enable: "enable=1",
  };

  filters = {
    display: jsonData.generalLinks[jsonData.generalLinks.length - 1],
  };

  componentDidMount() {
    this.fetchBanners(1);
  }

  fetchBanners = (page) => {
    const message = this.props.t("BannersViewSection.loadingPleaseWait");
    this.setLoading(message);

    const filters = this.getFilters();
    getBanners(page, filters).then(this.loadBanners);
  };

  filterByStatus = (filters) => {
    Object.assign(this.filters, filters);
    this.fetchBanners(1);
  };

  getDocumentTitle = () => {
    return this.props.t("BannersViewSection.documentTitle");
  };

  getFilters = () => {
    const filters = [];
    let item;
    const excluded = ["all"];
    for (let key in this.filters) {
      item = this.filters[key];
      if (item && excluded.indexOf(item) === -1) {
        filters.push(`${this.bannerFilters[item]}`);
      }
    }
    filters.push("created_at=desc");
    return filters;
  };

  loadBanners = (response) => {
    const { banners, pagination } = response.data.data;
    this.setState({
      currentPage: pagination.current_page,
      bannersLoaded: true,
      bannersData: banners,
      pagesTotal: pagination.last_page,
      pageSize: pagination.per_page,
      recordCount: pagination.total,
    });

    this.setLoading("");
  };

  onBannerAdd = () => {
    const modal = <AddBannerModal onBannerAdded={this.refreshContent} />;
    ModalController.showModal(modal);
  };

  showNewBannerSetModal = () => {
    const modal = (
      <div className="NewBannerSetModal">
        <NewBannerSetModal />
      </div>
    );
    ModalController.showModal(modal);
  };

  publishUnpublishedBanners = () => {
    const { t } = this.props;
    const modal = (
      <ConfirmationModal
        title={t("BannersViewHeader.modalConfirmTitle")}
        message={t("BannersViewHeader.modalConfirmMsg")}
        confirm={() => {
          publishUnpublishedBanners().then(() => {
            BannerController.notifyBannerDetailsUpdate();
          });
        }}
      />
    );
    ModalController.showModal(modal);
  };

  onPageChange = (page) => {
    this.fetchBanners(page);
  };

  refreshContent = () => {
    const { currentPage } = this.state;
    this.fetchBanners(currentPage);
  };

  setLoading = (loadingMessage) => {
    this.setState({
      loadingMessage: loadingMessage,
    });
  };

  showUploadBannersModal = () => {
    const modal = (
      <UploadBannersModal
        uploadImages={this.uploadBanners}
        onUploadFinished={this.fetchBanners}
      />
    );
    ModalController.showModal(modal);
  };

  uploadBanners = (file, type) => {
    return addBanner()
      .then((response) => {
        return this.uploadImageToBanner(file, type, response);
      }).catch(errr => {
        return { error: errr, file, type };
      });
  };

  uploadImageToBanner = (file, type, response) => {
    const { id_banner: bannerId } = response.data.data;
    return uploadBannerImage(bannerId, type, file)
      .then((response) => {
        return undefined; //valid upload
      })
      .catch(error => {
        return {
          error,
          bannerId,
          type,
          file
        }
      });
  };

  showCheckBox = (bannerId) => {
    const { bannersToDelete } = this.state;
    const index = bannersToDelete.indexOf(bannerId);
    if (index !== -1) {
      bannersToDelete.splice(index, 1);
    } else {
      bannersToDelete.push(bannerId);
    }
    this.setState({
      bannersToDelete: [...bannersToDelete]
    });
  };

  deleteBanners = () => {
    const { bannersToDelete } = this.state;
    const promises = [];
    for (let i = 0; i < bannersToDelete.length; i++) {
      promises.push(deleteBanner(bannersToDelete[i]));
    }
    const { t } = this.props;
    this.setLoading('Please wait...');
    Promise.all(promises).then(() => {
      this.refreshContent();
      displaySuccessNotification({
        duration: 3,
        message: t('BannerCard.bannerDeletionSuccessMsg')
      });
      this.setState({
        bannersToDelete: []
      });
      this.setLoading('');
    }).catch(() => {
      displayErrorNotification({
        duration: 3,
        message: t('BannerCard.bannerDeletionFailureMsg')
      });
      this.setLoading('');
    })
  };

  deleteMultipleBanners = () => {
    if (this.state.bannersToDelete.length) {
      const { t } = this.props;
      const modal = (
        <ConfirmationModal title={t('BannerCard.confirmDelete')}
          message={t('BannerCard.deleteMultipleQuestion')}
          yesBtnText={t('BannerCard.deleteBanners')}
          noBtnText={t('BannerCard.cancel')}
          confirm={this.deleteBanners} />
      )
      ModalController.showModal(modal);
    }
  };

  onToggleBannerSets = (val) => {
    this.setState({ showSets: val });
  }

  render() {
    return (
      <div className="BannersViewSection">
        <BannersMainView
          showCheckBox={this.showCheckBox}
          bannersToDelete={this.state.bannersToDelete}
          deleteMultipleBanners={this.deleteMultipleBanners}
          contentHeight={this.props.contentHeight}
          defaultDisplayFilter={this.filters.display}
          filterByStatus={this.filterByStatus}
          generalLinks={jsonData.generalLinks}
          listInfo={this.state}
          onPageChange={this.onPageChange}
          onBannerAdd={this.onBannerAdd}
          publishUnpublishedBanners={this.publishUnpublishedBanners}
          onBannerSetChanged={this.refreshContent}
          onBannerEdit={this.refreshContent}
          onDeleteBanner={this.refreshContent}
          showUploadBannersModal={this.showUploadBannersModal}
          showNewBannerSetModal={this.showNewBannerSetModal}
          setLoading={this.setLoading}
          showSets={this.state.showSets}
          onToggleBannerSets={this.onToggleBannerSets}
        />
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  withDocumentTitleUpdate
)(BannersViewSection);
