import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { Button, Checkbox, InputNumber, Input, Collapse, Divider } from "antd";
import PropTypes from 'prop-types';

import ModalDialog from "../../../../../../../../../components/modal/ModalDialog/ModalDialog";
import ConfirmationModal from '../../../../../../../../../components/modal/ConfirmationModal/ConfirmationModal';

import {
  editProperty,
  editWhitelabelConfig,
  getPropertyById,
  getWhitelabelConfig
} from "../../../../../../../../../services/properties-service/properties.service";
import {
  displayErrorNotification,
  displaySuccessNotification
} from "../../../../../../../../../services/notification-service/notification.service";
import { ModalController } from '../../../../../../../../../controllers/modal-controller/modal.controller';

import './EditPropertyModal.scss';

/* istanbul ignore file */

const OPTION_KEYS = {
  stars_page_enabled: 'stars_page_enabled',
  stars_page_exclusive_enabled: 'stars_page_exclusive_enabled',
  max_just_added_page_count: 'max_just_added_page_count',
  preview_max_count: 'preview_max_count',
  preview_time_period: 'preview_time_period',
  enable_benefits: 'enable_benefits',
  downloadable: 'downloadable',
  homepage_promo_banner: 'homepage_promo_banner'
};

const VARIABLE_DATA = { name: '', value: '' };

class EditPropertyModal extends Component {

  modalRef = React.createRef();
  inputValueRef = React.createRef();
  inputNameRef = React.createRef();
  previousVariableRef = React.createRef();

  state = {
    dataLoaded: false,
    stars_page_enabled: 0,
    stars_page_exclusive_enabled: 0,
    max_just_added_page_count: 10,
    preview_max_count: 1,
    preview_time_period: 24, // user can preview after this time expires, default 24h
    enable_benefits: 0,
    downloadable: 0,
    homepage_promo_banner: 0,
    variableData: VARIABLE_DATA,
    variables: [],
    editVariableId: null // null || id
  };

  componentDidMount() {
    const { t, applyToAll } = this.props;
    const message = t('EditPropertyModal.loadingPleaseWait');
    this.getModal().setLoading(message);
    const { propertyId } = this.props;
    const promises = [getWhitelabelConfig(propertyId)];

    if (!applyToAll) {
      promises.push(getPropertyById(propertyId));
    }

    Promise.all(promises)
      .then(response => {
        const {
          stars_page_enabled = 0,
          stars_page_exclusive_enabled = 0,
          max_just_added_page_count = 10,
          preview_max_count = 1,
          preview_time_period = 24,
          enable_benefits = 0,
          homepage_promo_banner = 0,
          variables = []
        } = response[0].data.data || {};
        const { downloadable = 0 } = response[1]?.data?.data || {};

        this.setState({
          stars_page_enabled,
          stars_page_exclusive_enabled,
          max_just_added_page_count,
          preview_max_count,
          preview_time_period,
          enable_benefits,
          variables,
          downloadable,
          homepage_promo_banner,
          dataLoaded: true
        });
        this.getModal().clearLoading();
      })
      .catch(this.onRequestFailure);
  }

  closeModal = () => this.getModal().closeModal();

  getActions = () => {
    const { t } = this.props;
    return (
      <div className='EditPropertyModal-actions'>
        <Button onClick={this.closeModal}>
          {t('EditPropertyModal.cancelBtn')}
        </Button>
        <Button onClick={this.saveChanges}>
          {t('EditPropertyModal.saveChanges')}
        </Button>
      </div>);
  };

  getModal = () => this.modalRef.current;

  getTitle = () => {
    const { t, propertyName } = this.props;
    const name = propertyName ? ` - ${propertyName}` : '';
    return `${t('EditPropertyModal.title')}${name}`;
  };

  previewTimPeriodMsg = () => {
    displayErrorNotification({
      duration: 6,
      message: <span><b>24</b> is max value for Max previews time period!</span>
    });
  };

  onInputChange = (key, value) => {
    if (isNaN(value)) return;

    if (key === OPTION_KEYS.preview_time_period && value > 24) {
      this.previewTimPeriodMsg();
    }

    this.setState(prevState => {
      const state = prevState[key] = parseInt(value, 10);
      return state;
    });
  };

  onRequestFailure = (error) => {
    console.log(error);
    this.getModal().clearLoading();
  };

  onSaveChangesFailure = (error) => {
    displayErrorNotification({
      duration: 3,
      message: this.props.t('EditPropertyModal.propertyEditFailure')
    });
    this.onRequestFailure(error);
  };

  onSaveChangesSuccess = () => {
    displaySuccessNotification({
      duration: 3,
      message: this.props.t('EditPropertyModal.propertyEditSuccess')
    });
    this.closeModal();
  };

  renderCheckbox = (label, key) => {
    const checked = this.state[key];
    const child = (
      <Checkbox checked={checked} onChange={this.onCheckboxChange.bind(this, key)} />
    );
    return this.renderField(label, child);
  };

  onTextIputChange = (event) => {
    const { name, value = '' } = event?.target || {};
    const { editVariableId, variables = [] } = this.state;

    if (editVariableId) {
      const updated = variables.map(item => {
        if (item.id === editVariableId) {
          return { ...item, [name]: value };
        }
        return item;
      });
      this.setState({ variables: updated });
      return;
    }

    this.setState({ variableData: { ...this.state.variableData, id: new Date().getTime(), [name]: value } });
  };

  onAddNewVariable = () => {
    const { variables = [], variableData = {} } = this.state;

    if (!variableData.name || !variableData.value) return;

    const variableExist = variables.find(v => v.name === variableData.name?.trim());
    if (variableExist) {
      displayErrorNotification({
        duration: 6,
        message: 'Variable already exists!'
      });
      return;
    }

    const { name = '', value = '' } = variableData;
    const trimSpace = { ...variableData, name: name.trim(), value: value.trim() };

    this.setState({ variables: [...variables, trimSpace], variableData: VARIABLE_DATA });
    if (this.inputValueRef.current) {
      this.inputValueRef.current.blur();
    }
    if (this.inputNameRef.current) {
      this.inputNameRef.current.focus();
    }
  };

  onEditVariable = (item) => {
    this.previousVariableRef.current = item;
    this.setState({ editVariableId: item.id });
  };

  onRemoveVariable = (varData) => {
    const { propertyName } = this.props;
    const { variables = [] } = this.state;
    const { id, name } = varData;

    if (!id) {
      console.error('No variable id found!');
      return;
    }

    const updated = variables.filter(item => item.id !== id);

    const msg = `Are you sure you want to delete "${name?.toUpperCase()}" variable?`;
    const modal = (
      <ConfirmationModal
        title={`Delete variable - ${propertyName}`}
        message={msg}
        yesBtnText='Confirm'
        noBtnText='Cancel'
        confirm={() => {
          this.setState({ variables: updated });
        }} />
    );
    ModalController.showModal(modal);
  };

  onSaveVariableEdit = () => this.setState({ editVariableId: null });

  onCancelVariableEdit = () => {
    const { variables = [] } = this.state;

    const updated = variables.map(item => {
      if (item.id === this.previousVariableRef?.current?.id) {
        return { ...this.previousVariableRef.current }
      }
      return item;
    });
    this.setState({ variables: updated, editVariableId: null });
  };

  renderTextInput = (name, value, placeholder, onChange, onPressEnter, ref) => {
    return (
      <Input
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onPressEnter={onPressEnter}
        ref={ref} />
    )
  };

  renderVariableInputs = (variableData = {}) => {
    return (
      <>
        {this.renderTextInput(
          'name',
          variableData.name,
          'Name',
          (val) => this.onTextIputChange(val),
          null,
          this.inputNameRef
        )}
        {this.renderTextInput(
          'value',
          variableData.value,
          'Value',
          (val) => this.onTextIputChange(val),
          () => this.onAddNewVariable(),
          this.inputValueRef
        )}
      </>
    )
  };

  renderInputBtnContainer = (children) => {
    return (
      <div className='InputBtnContainer d-flex align-center justify-space-between'>
        {children}
      </div>
    )
  };

  renderVariableIcon = (className, title, onClick) => {
    return (
      <i className={`fas ${className} cursor-pointer`} title={title} onClick={onClick}></i>
    )
  };

  renderVariables = () => {
    const { variables = [], editVariableId } = this.state;

    if (variables.length === 0) return null;

    return (
      <ul className='VariablesContainer'>
        {variables.map((item, index) => {
          const { id, name, value } = item;

          if (id === editVariableId) {
            return (
              <li key={id}>
                {this.renderInputBtnContainer(
                  <>
                    {this.renderVariableInputs(item)}
                    <span className='d-flex'>
                      {this.renderVariableIcon('fa-check', 'Save', this.onSaveVariableEdit)}
                      {this.renderVariableIcon('fa-times', 'Cancel', this.onCancelVariableEdit)}
                    </span>
                  </>
                )}
              </li>
            )
          }

          return (
            <li key={id} className='d-flex align-center justify-space-between'>
              <span className='flex-1 text-bold-600'>{index + 1}. {name}</span>
              <span className='flex-1 TextNoWrap' title={value}>{value}</span>

              <span>
                {this.renderVariableIcon('fa-pen', 'Edit', this.onEditVariable.bind(this, item))}
                {this.renderVariableIcon('fa-trash', 'Delete', this.onRemoveVariable.bind(this, item))}
              </span>
            </li>
          )
        })}
      </ul>
    )
  };

  renderFields = () => {
    const { t, propertyName, applyToAll } = this.props;
    const { variableData } = this.state;

    return (
      <React.Fragment>
        {this.renderCheckbox(t('EditPropertyModal.starsPageEnabled'), OPTION_KEYS.stars_page_enabled)}
        {this.renderCheckbox(t('EditPropertyModal.exclusiveStarsEnabled'), OPTION_KEYS.stars_page_exclusive_enabled)}
        {this.renderInputNumberField(t('EditPropertyModal.maxJustAddedPageCount'), OPTION_KEYS.max_just_added_page_count)}
        {this.renderCheckbox(t('EditPropertyModal.enableBenefits'), OPTION_KEYS.enable_benefits)}
        {!applyToAll && this.renderCheckbox(t('global.downloadable'), OPTION_KEYS.downloadable)}
        {!applyToAll && this.renderInputNumberField(t('EditPropertyModal.previewMaxCount'), OPTION_KEYS.preview_max_count)}
        {!applyToAll && this.renderInputNumberField(t('EditPropertyModal.previewMaxTime'), OPTION_KEYS.preview_time_period)}
        {this.renderCheckbox(t('Homepage promo banner'), OPTION_KEYS.homepage_promo_banner)}

        <Divider>{propertyName} variables</Divider>

        <Collapse>
          <Collapse.Panel header='Edit Variables'>
            {this.renderInputBtnContainer(
              <>
                {this.renderVariableInputs(variableData)}
                <Button className='text-bold-600' onClick={() => this.onAddNewVariable()}>Add</Button>
              </>
            )}
            {this.renderVariables()}
          </Collapse.Panel>
        </Collapse>
      </React.Fragment>
    );
  };

  renderInputNumberField = (label, key) => {
    const value = this.state[key];
    const child = (
      <InputNumber value={value} name={key} onChange={this.onInputChange.bind(this, key)} />
    );
    return this.renderField(label, child);
  };

  renderField = (label, children) => {
    return (
      <label className='ContentRow d-flex align-center justify-space-between'>
        <span className='text-bold-600'>{label}:</span>
        {children}
      </label>
    );
  };

  saveChanges = () => {
    const {
      stars_page_enabled,
      stars_page_exclusive_enabled,
      max_just_added_page_count,
      preview_max_count,
      preview_time_period,
      enable_benefits,
      variables,
      downloadable,
      homepage_promo_banner
    } = this.state;

    if (preview_time_period > 24) {
      this.previewTimPeriodMsg();
      return;
    }

    const message = this.props.t('EditPropertyModal.saveChangesMsg');
    this.getModal().setLoading(message);
    const { propertyId, applyToAll } = this.props;

    const config = {
      stars_page_enabled,
      stars_page_exclusive_enabled,
      max_just_added_page_count,
      preview_max_count,
      preview_time_period,
      enable_benefits,
      homepage_promo_banner,
      variables
    };

    const applyToPropertyId = applyToAll || propertyId;
    const promises = [editWhitelabelConfig(applyToPropertyId, config)];

    if (!applyToAll) {
      promises.push(editProperty(propertyId, { downloadable }));
    }

    Promise.all(promises)
      .then(this.onSaveChangesSuccess)
      .catch(this.onSaveChangesFailure);
  };

  onCheckboxChange = (key, event) => {
    const { checked } = event.target;
    this.setState(prevState => {
      const state = prevState[key] = checked ? 1 : 0;
      return state;
    });
  };

  render() {
    const { dataLoaded } = this.state;
    return (
      <ModalDialog
        className="EditPropertyModal"
        forwardedRef={this.modalRef}
        title={this.getTitle()}
        actions={this.getActions()}
      >
        <div className="EditPropertyModal-inner">
          {dataLoaded ? this.renderFields() : null}
        </div>
      </ModalDialog>
    );
  }
}

EditPropertyModal.propTypes = {
  propertyId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  propertyName: PropTypes.string,
  applyToAll: PropTypes.string
};

export default withTranslation()(EditPropertyModal);
